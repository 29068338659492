import React from 'react';
import styled from 'styled-components';

const KaidocSection: React.FC = () => {
  return (
    <SectionWrapper>
      <SectionTitle>
        <span className="highlight">카이닥</span>은 개인의 몸 에너지를 평가하여 건강을 관리하는 솔루션입니다
      </SectionTitle>
      <SectionDescription>
      경희대학교 한의과대학 이재동교수의 임상경험과 연구데이타를 기반으로 컴퓨터공학과와 현대그린푸드 헬스케어Lab의 도움을 받아 개발되었습니다.
      </SectionDescription>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  width: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(15, 103, 254, 0.1);

    @media (max-width: 991px) {
        padding: 1rem 2rem;
    }
`;

const SectionTitle = styled.h2`
  margin: 1rem 0 0;
  color: #000;
  text-align: center;
  font: 500 32px 'Gmarket Sans TTF', sans-serif;

  .highlight {
    color: #0f67fe;
  }

  @media (max-width: 991px) {
    font-size: 1.5rem;
    max-width: 100%;
  }
`;

const SectionDescription = styled.p`
  color: #000;
  text-align: center;
  margin-top: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default KaidocSection;
